<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading text-uppercase">
            {{ leaveCategory }} LEAVE REQUEST &nbsp; &nbsp;</span
          >
          <v-btn :color="getStatusColor(status)" rounded
            ><span style="font-family: kumbhRegular">{{ status }}</span></v-btn
          >
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 sm6 md4 pa-5>
                <v-layout wrap pt-2>
                  <v-flex xs12 v-if="previewUrl">
                    <v-avatar>
                      <v-img :src="mediaURL + previewUrl"></v-img>
                    </v-avatar>
                  </v-flex>
                  <v-flex xs12 v-else>
                    <v-avatar color="indigo" size="80">
                      <v-icon dark x-large> mdi-account-circle </v-icon>
                    </v-avatar>
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs8 sm8 text-left>
                        <span class="itemHeading2">Total Leaves</span>
                      </v-flex>
                      <v-flex xs4 sm4>
                        <span class="itemText2">{{ totalLeaves }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs8 sm8 text-left>
                        <span class="itemHeading2">Total Leaves Taken</span>
                      </v-flex>
                      <v-flex xs4 sm4>
                        <span class="itemText2">{{ leavesTaken }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs8 sm8 text-left>
                        <span class="itemHeading2"
                          >Total {{ leaveCategory }} Leaves</span
                        >
                      </v-flex>
                      <v-flex xs4 sm4>
                        <span class="itemText2">{{ dutyLeaves }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs8 sm8 text-left>
                        <span class="itemHeading2"
                          >{{ leaveCategory }} Taken</span
                        >
                      </v-flex>
                      <v-flex xs4 sm4>
                        <span class="itemText2">{{ dutyTaken }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md8 pa-5>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Employee Name</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ fullName }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Employee ID</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ empID }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Email ID</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ email }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Mobile</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ phone }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3 v-if="position != ''">
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Position</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ position }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Leave Applied on</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ formatDate(create_date) }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3 align-center>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Leave Required On</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <v-layout  align-center>
                          <v-flex>
                            <!-- Display only the visible dates -->
                          <span class="itemText2" v-for="(itm, i) in visibleDates" :key="itm">
                            {{ formatDate(itm) }}
                            <span v-if="i < visibleDates.length - 1">,</span>
                          </span>
                    
                          <!-- Add More Button -->
                          <v-btn
                            v-if="!showAllDates && leaveRequiredOn.length > 2"
                            small
                            text
                            color="blue"
                            class="ml-2"
                            @click="showAllDates = true"
                          >
                            See More
                          </v-btn>
                    
                          <!-- Close Button -->
                          <v-btn
                            v-if="showAllDates && leaveRequiredOn.length > 2"
                            small
                            text
                            color="red"
                            class="ml-2"
                            @click="showAllDates = false"
                          >
                            Close
                          </v-btn>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Leave Category</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ leaveCategory }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Purpose of Leave</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ reason }}</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>

                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Type of Leave</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left v-if="leaveType=='Fullday'">
                        <span class="itemText2">Full Day</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left v-if="leaveType=='AN'">
                        <span class="itemText2">Second Half</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left v-if="leaveType=='FN'">
                        <span class="itemText2">First Half</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>



                    <v-layout wrap py-3  v-if="status == 'approved'">
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Approved By</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ actionBy.name }} (EmpID: {{ actionBy.employeeId }})</span>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap py-3  v-if="status == 'rejected'">
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Rejected By</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ actionBy.name }} (EmpID: {{ actionBy.employeeId }})</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout
                      wrap
                      py-3
                      v-if="status == 'rejected'"
                    >
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Reason for Rejection</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left  v-if="remarks != '' && status == 'rejected'">
                        <span class="itemText2">{{ remarks }}</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left  v-else>
                        <span class="itemText2">No remarks</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="this.status == 'pending'">
              <v-flex xs6 align-self-center text-right pa-4>
                <v-dialog persistent v-model="approveDialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      style="
                        font-family: kumbhBold;
                        border: 2px solid #005f32;
                      "
                      class="mr-2"
                      plain
                      depressed
                      dark
                      block
                      v-on="on"
                      v-bind="attrs"
                    >
                      <span style="color: #005f32">Approve</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        align-self-center
                        pa-4
                        style="background: #005f32"
                        text-left
                      >
                        <span class="kumbhBold" style="color: #ffffff"
                          >Confirmation</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-card-title
                      >Are you sure you want to approve this leave
                      request?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        tile
                        dark
                        @click="approveDialog = false"
                        >Cancel</v-btn
                      >
                      &nbsp;
                      <v-btn
                        color="#005f32"
                        outlined
                        tile
                        @click="approve('approved')"
                        >Approve</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>

              <v-flex xs6 align-self-center text-left pa-4>
                <v-dialog persistent v-model="rejectDialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      style="
                        font-family: kumbhBold;
                        border: 2px solid red;
                      "
                      class="mr-2"
                      plain
                      block
                      depressed
                      dark
                      v-on="on"
                      v-bind="attrs"
                    >
                      <span style="color: red">Reject</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        align-self-center
                        pa-4
                        style="background: #005f32"
                        text-left
                      >
                        <span class="kumbhBold" style="color: #ffffff"
                          >Confirmation</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-card-title
                      >Are you sure you want to reject this leave
                      request?</v-card-title
                    >
                    <v-layout wrap pa-6>
                      <v-flex xs12 align-self-center pa-2>
                        <v-textarea
                          label="Remarks"
                          v-model="remarks"
                          outlined
                          hide-details
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        tile
                        dark
                        @click="rejectDialog = false"
                        >Cancel</v-btn
                      >
                      &nbsp;
                      <v-btn
                        color="#005f32"
                        outlined
                        tile
                        @click="validateReject('rejected')"
                        >Reject</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      visible: false,
      create_date: "",
      previewUrl: "",
      appLoading:false,
      data: [],
      empID: "",
      fullName: "",
      email: "",
      phone: "",
      leaveRequiredOn: "",
      leaveCategory: "",
      leaveType: "",
      reason: "",
      status: "",
      position: "",
      remarks: "",
      actionBy:"",
      TL: 15,
      LC: null,
      leaveCategories: [],
      showAllDates: false,
      approveDialog: false,
      rejectDialog: false,
    };
  },
  mounted: function () {
    this.getview1();
  },
  computed: {
    visibleDates() {
      // Show all dates if 'showAllDates' is true, otherwise show the first two
      return this.showAllDates ? this.leaveRequiredOn : this.leaveRequiredOn.slice(0, 2);
    },
    totalLeaves() {
      var tl = 0;
      for (var i = 0; i < this.leaveCategories.length; i++) {
        tl = tl + this.leaveCategories[i].totalLeaves;
      }
      return tl;
    },
    leavesTaken() {
      var lt = 0;
      for (var j = 0; j < this.leaveCategories.length; j++) {
        lt = lt + this.leaveCategories[j].availedLeaves;
      }
      return lt;
    },
    dutyLeaves() {
      var lt = 0;
      for (var i = 0; i < this.leaveCategories.length; i++) {
        if (this.leaveCategory == this.leaveCategories[i].leaveCategory)
          lt = this.leaveCategories[i].totalLeaves;
      }
      return lt;
    },
    dutyTaken() {
      var lt = 0;
      for (var i = 0; i < this.leaveCategories.length; i++) {
        if (this.leaveCategory == this.leaveCategories[i].leaveCategory)
          lt = this.leaveCategories[i].availedLeaves;
      }
      return lt;
    },
  },
  methods: {
    getStatusColor(status) {
      if (status === "approved") {
        return "success";
      }
      if (status === "rejected") {
        return "danger";
      }
      if (status === "pending") {
        return "info";
      }
    },
    validateReject(value){
      if(value=='rejected'){
        if(!this.remarks)
      {
        this.msg = "Please Provide Remark";
        this.showsnackbar = true;
      }
      else
      this.approve(value)
    }
    },
    approve(value) {
      this.visible = true;
      var app = {};
      app["status"] = value;
      app["remarks"] = this.remarks;
      app["id"]= this.$route.query.id
      axios({
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: app,
        url: "/employee/leave/application/approve/reject",
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push("/Attendance/leaveRequests");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getview1();
          }
        })
        .catch((e) => {
          this.visible = true;
          this.errors.push(e);
        });
    },

    getview1() {
      this.visible = true;
      axios({
        method: "POST",
        url: "/employee/leave/application/view/" + this.$route.query.id,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.visible = false;
            this.empID = response.data.data.uid.employeeId;
            this.fullName = response.data.data.uid.name;
            this.email = response.data.data.uid.email;
            this.phone = response.data.data.uid.phonenumber;
            this.previewUrl = response.data.data.uid.photo;
            this.reason = response.data.data.reason;
            this.leaveRequiredOn = response.data.data.leaveRequiredOn;
            this.leaveType = response.data.data.leaveType;
            this.status = response.data.data.status;
            this.create_date = response.data.data.create_date;
            this.leaveCategory = response.data.data.leaveCategory;
            this.leaveCategories =
              response.data.data.userLeaveDetailsId.leaveDetails;
            this.position = response.data.data.uid.roleName;
            this.remarks = response.data.data.remarks;
            this.actionBy=response.data.data.actionBy;
           
            // for (var j = 0; j < this.leaveRequiredOn.length; j++) {
            //   var dt = this.leaveRequiredOn[j];
            //   dt = new Date(dt);
            //   dt = dt.toString();
            //   dt = dt.slice(3, 15);
            //   this.leaveRequiredOn[j] = dt;
            // }
            

            // var temp
            // for (var j = 0; j < this.leaveRequiredOn.length; j++) {
            //   var ty
            // }
          } else if (response.data.status === false) {
            alert(response.data.msg);
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    formatDate(item) {
  const dt = new Date(item);
  const day = String(dt.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
  const month = String(dt.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad
  const year = dt.getFullYear(); // Get full year
  return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
}

  },
};
</script>
  <style scoped>
.modal-footer {
  display: none !important;
}
td {
  font-weight: bold;
}
</style>
  